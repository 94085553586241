import ApiService from "@/core/services/ApiService";
import { processErrors } from "./errorsProcessing";

export const url = "api/v1/document";

export async function searchDocuments(request) {
  return await ApiService.post(url + "/search", request as never)
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}

export async function uploadDocuments(request) {
  const headers = { "Content-Type": "multipart/form-data" };
  await ApiService.postForm(url + "/upload", request, { headers: headers })
    .then()
    .catch(({ response }) => {
      processErrors(response);
    });
}

export async function downloadDocument(doc) {
  return await ApiService.query(url + "/getFile?id=" + doc.id, {
    responseType: "blob",
  }).then((res) => {
    const fileURL = window.URL.createObjectURL(new Blob([res.data]));
    const fileLink = document.createElement("a");

    fileLink.href = fileURL;
    //TODO temp
    fileLink.setAttribute("download", doc.initFileName);
    document.body.appendChild(fileLink);

    fileLink.click();
  });
}

export async function deleteDocument(doc) {
  if (doc) {
    await ApiService.delete(url + "/" + doc?.id)
      .then()
      .catch(({ response }) => {
        processErrors(response);
      });
  }
}
