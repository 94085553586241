
import { defineComponent, onMounted, ref, reactive, nextTick } from "vue";
import { getOrganization } from "@/core/services/JwtService";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import Multiselect from "@vueform/multiselect";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import PaginationUi from "@/components/ABilling/Pagination.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import FileUpload from "vue-upload-component";
import {
  deleteDocument,
  downloadDocument,
  searchDocuments,
  uploadDocuments,
} from "@/api/documents.api";
import { getList } from "@/api/dropdown.api";
import { helpers, maxLength } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

export interface Pagination {
  currentPage: number;
  totalPages: number;
  totalCount: number;
  pageSize: number;
}

interface UploadDocument {
  file?: any;
  documentOwner?: string;
  documentType?: string;
  description?: string;
}

interface DocumentType {
  value: string;
  name: string;
}

export interface Data {
  uploadFiles?: UploadDocument[];
  isLoaded: boolean;
  items: any[];
  keyword: string;
  pagination: Pagination;
  orderBy: string;
  isDecr: boolean;
  panel: any;
  IsAdvancedOrderBy: boolean;
  showInactive: boolean;
  modalPaymentId;
  modal;
  dict: {
    documentType: DocumentType[];
  };
}
export default defineComponent({
  name: "DocumentsList",
  components: { PaginationUi, FileUpload, Multiselect },
  props: ["ownerId", "documentOwner", "documentType"],
  setup(props) {
    const fileTypes = "bmp,gif,jpg,jpeg,png,webp,txt,pdf,docx,xls,xlsx";
    const ext =
      "image/bmp,image/png,image/gif,image/jpeg,image/webp,text/plain,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const orderList = [
      {
        name: "Document Name",
        key: "initFileName",
        isFilter: true,
        keyword: null,
      },
      {
        name: "Document Type",
        key: "documentType",
        isFilter: true,
        keyword: null,
      },
      {
        name: "Description",
        key: "description",
        isFilter: true,
        keyword: null,
      },
      {
        name: "Upload Date",
        key: "creationDate",
        isFilter: true,
        keyword: null,
      },
    ];

    let organizationId = ref<string | null>("");

    let data = reactive<Data>({
      isLoaded: false,
      uploadFiles: [],
      items: [],
      keyword: "",
      orderBy: "creationDate",
      IsAdvancedOrderBy: false,
      isDecr: true,
      showInactive: false,
      pagination: {
        currentPage: 1,
        totalPages: 0,
        totalCount: 0,
        pageSize: 50,
      },
      panel: {},
      modalPaymentId: null,
      modal: false,
      dict: {
        documentType: [],
      },
    });

    const facilityEncounterRules = {
      uploadFiles: {
        $each: helpers.forEach({
          description: {
            maxLength: maxLength(250),
          },
        }),
      },
    };

    const v$ = useVuelidate(facilityEncounterRules, data as never);

    async function cleanFilter() {
      data.keyword = "";
      data.orderBy = "creationDate";
      data.IsAdvancedOrderBy = false;
      data.isDecr = true;

      await getAll();
    }

    onMounted(async () => {
      organizationId.value = getOrganization();
      setCurrentPageTitle("Payment Management");
      data.dict.documentType = await getList({ type: "Document Type" });

      await getAll();

      data.isLoaded = true;
    });

    async function selectFilter(header) {
      if (!header.key || !header.isFilter) {
        return;
      }
      if (data.orderBy == header.key) {
        data.isDecr = !data.isDecr;
      } else {
        data.isDecr = false;
      }
      data.orderBy = header.key;
      data.IsAdvancedOrderBy = header.IsAdvancedOrderBy;

      await search();
    }

    function getSortInfo(key) {
      if (data.orderBy == key) return true;
      return false;
    }

    async function search() {
      await nextTick();
      data.pagination.currentPage = 1;
      await getAll();
    }

    async function getAll() {
      let order = data.orderBy;
      if (data.isDecr) {
        order = order + " Desc";
      }

      let orderBy: string[] | null = null;
      let advancedOrderBy: string | null = null;

      if (order && !data.IsAdvancedOrderBy) {
        orderBy = [order];
      } else {
        advancedOrderBy = order;
      }
      let request = {
        ownerId: props.ownerId,
        globalSearch: data.keyword,
        pageNumber: data.pagination.currentPage,
        pageSize: data.pagination.pageSize,
        orderBy: orderBy,
        advancedOrderBy: advancedOrderBy,
      };

      const res = await searchDocuments(request);
      data.items = res.data;
      data.pagination.currentPage = res.currentPage;
      data.pagination.totalPages = res.totalPages;
      data.pagination.totalCount = res.totalCount;
      data.pagination.pageSize = res.pageSize;
    }

    async function clearSearch(header) {
      header.keyword = null;
      await search();
    }

    async function pageChanged(page) {
      data.pagination.currentPage = page;
      await getAll();
    }

    async function pageSizeChanged(pageSize) {
      data.pagination.pageSize = pageSize;
      data.pagination.currentPage = 1;
      await getAll();
    }

    function getNow() {
      var date = new Date();
      date.setHours(0, 0, 0, 0);
      return date;
    }

    async function download(insuranceRemit) {
      await downloadDocument(insuranceRemit);
    }

    async function uploadFiles() {
      let result = await v$.value.$validate();
      if (!result) return;

      var formData = new FormData();
      data.uploadFiles?.forEach((doc, i) => {
        if (doc.description)
          formData.append(`documents[${i}].description`, doc.description);
        if (doc.documentOwner)
          formData.append(`documents[${i}].documentOwner`, doc.documentOwner);
        if (doc.documentType)
          formData.append(`documents[${i}].documentType`, doc.documentType);
        formData.append(`documents[${i}].ownerId`, props.ownerId);
        if (doc.file) formData.append(`documents[${i}].file`, doc.file.file);
      });

      await uploadDocuments(formData);
      await getAll();
      data.uploadFiles = [];
    }

    const files = ref([]);

    async function inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        const extFilters = ext.split(',');
        if (!extFilters.includes(newFile.type)) {
          return prevent();
        }
        // Filter system files or hide files
        if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
          return prevent();
        }

        if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
          return prevent();
        }
      }
    }

    function inputFile(newFile, oldFile) {
      if (newFile && !oldFile) {
        // add
        let newDocument = {
          file: newFile,
          documentOwner: props.documentOwner,
          documentType: undefined,
          documentTypeDisabled: false,
          description: "",
        };
        if (props.documentType) {
          newDocument.documentType = props.documentType;
          newDocument.documentTypeDisabled = true;
        }
        data.uploadFiles?.push(newDocument);
      }
      if (newFile && oldFile) {
        // update
        console.log("update", newFile);
      }

      if (!newFile && oldFile) {
        // remove
        console.log("remove", oldFile);
      }
    }

    function deleteFileForUpload(item) {
      if (!data.uploadFiles) return;
      const index = data.uploadFiles.indexOf(item, 0);
      if (index > -1) {
        data.uploadFiles.splice(index, 1);
      }
    }

    async function deleteFile(item) {
      Swal.fire({
        title: "Are you sure you want to delete this Document?",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Yes",
        denyButtonText: "No",
        allowOutsideClick: false,
        customClass: {
          actions: "my-actions",
          cancelButton: "order-1 right-gap",
          confirmButton: "order-2",
          denyButton: "order-3",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          await deleteDocument(item);
          await getAll();
        }
      });
    }

    const upload = ref(null);

    return {
      organizationId,
      clearSearch,
      data,
      deleteFile,
      deleteFileForUpload,
      download,
      fileTypes,
      ext,
      orderList,
      search,
      getSortInfo,
      selectFilter,
      cleanFilter,
      pageChanged,
      pageSizeChanged,
      getNow,
      uploadFiles,

      files,
      upload,
      inputFilter,
      inputFile,

      v$,
    };
  },
});
